@import url("https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap");
@import "./../../styles/Components.scss";

.signin {
  padding: 30px;

  .no-underline-title {
    text-align: left;
    margin: 0;
  }

  .img {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    height: 40px;
    .vote-img {
      height: 150px;
      position: relative;
      bottom: 90px;
      margin-right: 15px;
    }
  }

  .form {
    .text-input {
      padding: 8px 0;
      background-color: white;
      border-radius: 0px;
      border: none;
      border-bottom: 3px black solid;
      font-style: normal;
      font-weight: bold;
      margin: 30px 0;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
    }

    .error-msg {
      color: red;
      font-weight: bold;
      position: relative;
      bottom: 32px;
      border-top: 3px solid red;
      height: 0;
    }

    .recaptcha {
      display: flex;
      justify-content: center;
      margin: 20px 0;
    }

    .signup-link {
      text-align: center;
      margin-top: 10px;
    }
  }
}
