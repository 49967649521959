@import "src/styles/Components";
@import "src/styles/Fonts";
@import "src/styles/Variables";

.election-reminder {
  .electionReminderContainer {
    display: flex;
    flex-direction: column;
  }

  .title {
    margin-top: 24px;
    margin-left: 30px;
    margin-right: 30px;
    @include heading-text;
    text-align: left !important;
    width: calc(100% - 60px);
    // float: left !important;
  }

  .underline {
    @include text-underline;
  }

  .at-form-submit {
    display: flex;
    justify-content: center;
  }

  .ngp-form label {
    color: black !important;
    font-weight: bold !important;
    margin-top: 18px !important;
    margin-bottom: 0 !important;
    font-family: "Lato", sans-serif !important;
  }

  .ngp-form small {
    display: none;
  }

  .ngp-form {
    width: calc(100% - 20px);
    margin: 0 10px;
  }

  .at-legend {
    font-family: "Lato", sans-serif !important;
    width: auto;
  }


  .reminders-completed {
    font-family: "Lato", sans-serif;
    color: black;
    margin: 0px 30px;
  }

  .reminders-completed h3 {
    font-weight: bold;
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .at-text input {
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    border-width: 3px !important;
    border-color: black !important;
    border-radius: 0px !important;
  }
  .thankYou {
    background-color: white !important;
    padding: 0 20px;
  }

  #NVSignupForm61312-ContactInformation-SmsLegalDisclaimer {
    margin-top: 40px;
    font-family: "Lato", sans-serif;
    color: black;
  }

  #NVSignupForm61312-ContactInformation-SmsLegalDisclaimer a {
    color: black;
    text-decoration: underline;
  }

  .btn-at-primary {
    @include button;
  }

  .contributions h1 {
    width: 100%;
    margin-left: 0 !important;
    margin-top: 40px;
    font-family: $bebas-neue !important;
    font-size: 24px !important;
    text-align: left !important;
  }

  .contributions p {
    width: 100%;
    margin-top: 40px;
    margin-left: 0 !important;
  }

  #continue {
    @include button;
    width: auto !important;
    margin: 40px 30px !important;
  }
}
