@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Lato:wght@400;700;900&display=swap");

$bebas-neue: "Bebas Neue", cursive;
$lato: "Lato", sans-serif;

// headings
h1,
.h1 {
  font-family: $bebas-neue;
  font-weight: normal;
  font-size: 48px;
  margin: 0;
}

h2,
.h2 {
  font-family: $bebas-neue;
  font-weight: normal;
  font-size: 36px;
  margin: 0;
}

h3,
.h3 {
  font-family: $bebas-neue;
  font-weight: normal;
  font-size: 24px;
  margin: 0;
}

// body
.b1 {
  font-family: $lato;
  font-weight: bold;
  font-size: 18px;
  margin: 0;
}

.b2 {
  font-family: $lato;
  font-weight: normal;
  font-size: 18px;
  margin: 0;
}

.b3 {
  font-family: $lato;
  font-weight: 900;
  font-size: 16px;
  margin: 0;
}

p,
.b4 {
  font-family: $lato;
  font-weight: normal;
  font-size: 16px;
  margin: 0;
}

.b5 {
  font-family: $lato;
  font-weight: 900;
  font-size: 14px;
  margin: 0;
}

.b6 {
  font-family: $lato;
  font-weight: normal;
  font-size: 14px;
  margin: 0;
}

// center text
.text-center {
  text-align: center;
}

// text color
.text-black {
  color: $black;
}

// underline
.underline {
  text-decoration: underline;
  text-decoration-color: $teal;
  text-decoration-thickness: 10px;
  text-decoration-skip-ink: none;
  // non-iOS devices
  @supports not (-webkit-touch-callout: none) {
    text-decoration: underline $teal 10px;
    text-underline-offset: -5px;
  }
}

// text shadow
.number-shadow {
  -webkit-text-stroke: 2px $black;
  text-shadow: -9px 9px 0px $black;
}

.number-shadow-sm {
  -webkit-text-stroke: 2px $black;
  text-shadow: -6px 6px 0px $black;
}
