.verifysuccess {
  padding: 80px 30px;
  text-align: center;

  img {
    margin-bottom: 20px;
  }

  .loading {
    margin-top: 30px;
  }
}
