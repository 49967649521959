@import "src/styles/Fonts";

.Legal {
  padding: 30px;

  h1 {
    margin-bottom: 10px;
  }

  h2 {
    margin-bottom: 5px;
  }

  p {
    margin-bottom: 20px;
  }
}
