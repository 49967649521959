.isValid {
  color: green;
  flex: 1;
  text-align: end;
}

.validating {
  color: gray;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;
}

.invalid {
  color: red;
  flex: 1;
  text-align: end;
}

.addressError {
  height: 24px;
  color: red;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: -12px;
  margin-bottom: 12px;
}