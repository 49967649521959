@mixin button-base() {
  height: 64px;
  width: 100%;
  line-height: 38px;
  letter-spacing: 0.03em;
  font-size: 32px;
  @include center();
  font-family: $bebas-neue;
  background: $gradient;
  color: black;
  border: 4px solid black;
  border-radius: 40px;
  position: relative;
  padding: 5px;
  margin: 8px 0;
  ::after {
    content: "";
    position: absolute;
    border-radius: 40px;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    border: white 4px solid;
  }
}

// put button text in <span></span> for white border and/or text gradient

button.gradient {
  @include button-base();
}

button.text-gradient {
  @include button-base();
  color: white;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  :first-child {
    background: linear-gradient(270deg, #ffed10 0%, #02ddc3 100%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

button.inverted {
  @include button-base();
  background: $black;
  color: $teal;
  span {
    background: $gradient;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    -moz-text-fill-color: transparent;
    -webkit-text-fill-color: transparent;
    display: inline-block;
    font-size: 32px !important;
  }
}

button.small {
  width: -moz-fit-content;
  width: fit-content;
  height: 47px;
  font-size: 24px;
  padding: 9px 20px;
  line-height: 29px;
}

button.fit-content {
  width: -moz-fit-content;
  width: fit-content;
}
