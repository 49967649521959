.bold-text {
    font-weight: bold;
}

.info {
    margin-top: 40px;
}

.img-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 30px;
    margin-right: 30px;

    img {
        max-width: 40%;
        margin: 2%;
    }
}

.copy-link-again {
    text-align: center;
    margin: 30px;
}