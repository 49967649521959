@import "../../../styles/Components";
@import "../../../styles/Fonts";
@import "../../../styles/Variables";
@import "../../../styles/Classes";

.Rewards {
    h1 {
        @include text-underline;
        color: white;
        font-size: 4rem;
        text-transform: capitalize;
    }

    .description-text {
        color: white;
    }

    .sponsor-section {
        margin: 20px 0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        
        img {
            margin: 10px 0px;
            height: 20vh;
            width: 30vh;
            object-fit: contain;
        }

        .sponsor {
            text-align: center;
            margin: 20px 0px;
            display: flex;
            flex-direction: column;
            align-items: center;
            a {
                text-decoration: none !important;
            }
            h5 {
                
                font-size: 2rem;
                font-family: $bebas-neue;
                text-transform: uppercase;
            }
            p {
                margin: 0 2rem;
            }
            
            .challenge-button {
                margin: 2rem;
                @include button;
                width: 80% !important;
            }
        }
    }
}