// centering
@mixin vertical-center() {
  display: flex;
  justify-content: center;
}

@mixin horizontal-center() {
  display: flex;
  align-items: center;
}

@mixin center() {
  @include vertical-center();
  @include horizontal-center();
}

// media queries
//Large Screen - 446 to 500 screen size
@mixin lg-screen {
  @media only screen and (min-width: $max-screen-sz) {
    @content;
  }
}

//Medium Screen - 391 to 445 screen size
@mixin md-screen {
  @media only screen and (min-width: $md-screen-sz) {
    @content;
  }
}

//Medium Screen - 336 to 390 screen size
@mixin sm-screen {
  @media only screen and (min-width: $sm-screen-sz) {
    @content;
  }
}

// XSmall Screen - 280 to 335 screen size
@mixin xs-screen {
  @media only screen and (min-width: $xs-screen-sz) {
    @content;
  }
}
