@import "src/styles/Fonts";

.invite-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .invite-menu {
    height: 95%;
    width: 100%;
    max-width: 500px;
    position: fixed;
    bottom: -130vh;
    transition: 600ms;
    background-color: white;
    color: black;
    width: 100%;
    overflow: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $lato;
    border-top: 2px solid black;

    .invite-toggle {
      width: 100%;
      height: 60px;
      display: flex;
      margin-top: 20px;

      a.nav-link,
      a.nav-link:hover {
        color: black;
        fill: black;
        margin: 25px;
      }

      #close-icon {
        position: relative;
        right: 20px;
        bottom: 20px;
        svg {
          width: 30px;
          height: 30px;
        }
      }

      .invite-title {
        position: relative;
        right: 40px;
        bottom: 5px;
      }
    }

    .invite-title {
      font-family: $bebas-neue;
      margin: auto;
      font-size: 36px;
    }

    .info {
      display: inherit;
      flex-direction: column;
      align-items: center;
      text-align: center;

      p {
        margin: 0;
        position: relative;
        bottom: 10px;

        .preview-button {
          background-color: transparent;
          border: none;
          cursor: pointer;
          text-decoration: underline;
          display: inline;
          margin: 0;
          padding: 0px 0px 0px 2px;
        }
      }
    }

    .section {
      width: 100%;
      margin-top: 30px;

      p {
        margin: 0;
      }

      .sub-heading {
        font-weight: bolder;
      }

      .invite-icon {
        width: 50px;
        height: 50px;
        margin: 0 30px 5px 30px;
      }

      .email-icon {
        width: 48px;
        height: 48px;
        margin: 0 30px 5px 30px;
      }

      .text-icon {
        width: 45px;
        height: 45px;
        margin: 0 30px 5px 30px;
      }

      .instagram-button,
      .sms-button {
        background: none;
        border: none;
        padding: 0;
        color: black;
      }

      button {
        margin-top: 15px;
      }
    }

    .link-container {
      display: flex;
      align-items: baseline;
      margin-bottom: 30px;

      input {
        height: 30px;
        margin-top: 20px;
        margin-right: 20px;
        border: none;
        background-image: linear-gradient(
          90deg,
          rgba(0, 212, 255, 1) 26%,
          rgba(10, 255, 73, 1) 63%,
          rgba(241, 255, 0, 1) 100%
        );
        background-size: 100% 4px;
        background-repeat: no-repeat;
        background-position: left bottom;
        background-position: 120 0;
        font-weight: bold;
      }

      button {
        border-radius: 20px;
        background-color: black;
        border: 0;
        color: white;
        font-family: $bebas-neue;
        font-size: 20px;
        padding: 0 20px 0 20px;
        height: min-content;
        margin-bottom: 7px;
      }

      .unique-link {
        margin-right: 20px;
        max-width: 70%;

        p {
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }

    .underline {
      margin-top: 4px;
      height: 3px;
      background: linear-gradient(270deg, #ffed10 0%, #02ddc3 100%);
    }
  }
}

.invite-menu.active {
  bottom: 0;
  transition: 400ms;
}
