#header {
  position: fixed;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 375px;
}

#root {
  background-color: white;
  color: black;
  min-width: 375px;
}

#app-outer-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  top: 57px;
  min-width: 375px;
  z-index: 0;
}

#app-wrapper {
  width: 100vw;
  max-width: 500px;
  min-width: 375px;
}
