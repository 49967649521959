.popup-modal {
  *{z-index: 100 !important;}
  .modalBackground {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.623);
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &-scroll {
      @extend .modalBackground;
      overflow-y: scroll;
    }
  }

  .modalContainer {
    width: 80%;
    max-width: 375px;
    min-width: 310px;
    background-color: black;
    color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;

    .titleCloseBtn {
      display: flex;
      justify-content: flex-end;
      margin-top: 5px;
      margin-right: 8px;
    }

    .titleCloseBtn button {
      background-color: transparent;
      border: none;
      font-size: 25px;
      cursor: pointer;
      color: white;
    }

    .content {
      padding: 20px 35px;
      height: 100%;
      text-align: center;

      button {
        margin: auto;
        margin-top: 35px;
        margin-bottom: 35px;
        font-size: 25px;
        height: 50px;
      }
    }

    &--light {
      @extend .modalContainer;
      background-color: white;
      color: black;
      .titleCloseBtn button {
        @extend .titleCloseBtn;
        color: black;
      }
      &-expect {
        @extend .modalContainer--light;
        position: absolute;
        top: 65px;
      }  
    }
    
  }
}
