@import "src/styles/main";

.sidebar {
  .top {
    svg {
      width: 35px;
      height: 35px;
      position: relative;
      right: 10px;
    }
    :hover {
      cursor: pointer;
    }
  }

  .greeting {
    margin: 50px 0;
    .text {
      font-family: $bebas-neue;
      font-weight: normal;
      font-size: 36px;
    }
    .avatar-greeting {
      display: flex;
      align-items: center;
      .blob {
        width: 0;
        img {
          width: 80px;
        }
      }
      .avatar {
        width: 65px;
        position: relative;
        left: 8px;
      }
      .text {
        margin-left: 30px;
      }
    }
  }

  .links,
  .bottom-links {
    margin-left: 20px;

    button {
      position: relative;
      right: 24px;
      font-size: 28px;
    }
  }

  .links {
    .container {
      padding: 0;
      margin-bottom: 48px;
      display: flex;
      flex-direction: column;
      a {
        color: white;
        text-decoration: none;
        margin-bottom: 24px;
        font-family: $bebas-neue;
        font-weight: normal;
        font-size: 28px;
      }
    }
  }

  .bottom-links {
    display: flex;
    flex-direction: column;
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      text-decoration: none;
      display: inline;
      margin: 5px 0px;
      padding: 0;
      color: white;
      font-weight: 700;
      font-size: 18px;
      line-height: 21.6px;
      text-align: left;
      right: 0px;
    }
    
    a {
      padding: 0;
      color: white;
      text-decoration: none;
      margin: 5px 0;
      font-weight: 700;
      font-size: 18px;
      line-height: 21.6px;
    }
  }

  .popup-modal {
    .buttons {
      width: 100%;
      a {
        text-decoration: none;
      }
      button {
        margin: auto;
        margin-top: 35px;
        margin-bottom: 35px;
      }
    }
  }
}
