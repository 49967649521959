.loading-wheel-outer-container {
    position: fixed;
    top: 57px;
    left: 0;
    width: 100%;
    height: calc(100vh - 57px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  
  .loading-wheel-outer-container-block {
    width: 100%;
    height: calc(100vh - 75px);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loading-wheel-inner-container {
    background-color: rgba(111, 111, 111, 0.5);
    width: 500px;
    max-width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 250ms ease-in-out 1 forwards;
  }

  .loading-wheel-inner-container-white {
    background-color: white;
    width: 500px;
    max-width: 100vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: fadeIn 250ms ease-in-out 1 forwards;
  }
  
  .lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #ffed10;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #ffed10 transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }