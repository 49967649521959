@import "./../../styles/Components.scss";
@import "./../../styles/Fonts.scss";
@import "./../../styles/Variables.scss";
@import "./../../styles/Classes.scss";

.signup {
  padding: 30px;
  .red {
    color: red;
    border-color: red;
  }

  .register-input {
    width: 100%;
    margin-bottom: 30px;
  }

  .error-msg {
    color: red;
    font-weight: bold;
    position: relative;
    bottom: 34px;
    border-top: 4px solid red;
    height: 0;
  }

  .unknown-error {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0;
    position: relative;
    top: 13px;
  }

  .normal-title,
  .no-underline-title {
    text-align: left;
    margin: 0;
  }

  .form {
    margin-top: 25px;
    .required-text {
      font-size: 14px;
    }
    .text-input {
      padding: 8px 0;
      background-color: white;
      border-radius: 0px;
      border: none;
      border-bottom: 3px black solid;
      font-style: normal;
      font-weight: bold;
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
    }

    .small-title {
      text-align: left;
      margin-top: 40px;
    }
    .avatar-container {
      margin: 20px 0;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      input[type="radio"] {
        display: none;
        &:checked + label {
          .avatar-img {
            opacity: 1;
          }
        }
      }
      .avatar-img {
        opacity: 0.5;
        min-width: 150px;
        width: 40vw;
        max-width: 200px;
        // prevents image-drag
        -webkit-user-drag: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      .avatar-img:hover {
        opacity: 1;
        cursor: pointer;
      }
    }

    .recaptcha {
      width: 100%;
      margin-top: 40px;
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      overflow-x: auto;
      overflow-y: hidden;
    }

    .tos,
    .signin {
      text-align: center;
      padding: 0;
      margin-top: 10px;
      .link {
        font-weight: bold;
        color: black;
        text-underline-offset: 2px;
      }
    }

    .gradient-button {
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .signupInfo {
      display: flex;
      flex-direction: column;
      position: relative;
      padding-top: 40px;
      box-sizing: border-box;
    }
  }
}
