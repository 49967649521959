@import "src/styles/Components";
@import "src/styles/Fonts";
@import "src/styles/Variables";
@import "src/styles/Classes";

.challenger-welcome {
  .link {
    text-decoration: underline;
    font-weight: bolder;
    &:hover {
      cursor: pointer;
    }
  }

  .content-1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .background {
      width: 100%;
      height: 300px;
    }
    .container {
      height: 0;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .logo {
        position: relative;
        bottom: 170px;
      }
    }
  }

  .content-2 {
    padding: 30px;
    text-align: center;
    .normal-text {
      margin: 30px 0;
      text-align: left;
    }
    .small-text {
      margin-top: 16px;
      text-align: center;
    }
    .link-2 {
      margin-top: 0.7em;
    }
  }

  .content-3 {
    padding: 30px;
    padding-bottom: 40px;
    padding-top: 0px;
    .normal-heading {
      margin-top: 30px;
    }
    .small-text {
      text-align: center;
      margin-top: 24px;
    }
    .gradient-button {
      margin-top: 30px;
    }
  }
}
