// colors
$black: #101010;
$yellow: #ffed10;
$cream: #fffcd2;
$nightsky: #081e5b;
$graphite: #7f7f7f;
$smoke: #bfbfbf;
$lightgrey: #dedede;
$white: #ffffff;
$blue: #02ddc3;
