// screen sizes
$max-screen-sz: 500px;
$md-screen-sz: 445px;
$sm-screen-sz: 390px;
$xs-screen-sz: 335px;
$min-screen-sz: 280px;

// colors
$black: #101010;
$yellow: #ffed10;
$cream: #fffcd2;
$teal: #02ddc3;
$graphite: #7f7f7f;
$smoke: #bfbfbf;
$lightgrey: #dedede;
$white: #ffffff;
$gradient: linear-gradient(270deg, #ffed10 0%, #02ddc3 100%);

// spacing
$spacing-sm: 24px;
$spacing-md: 32px;
$spacing-lg: 40px;
