.verify {
  padding: 40px 30px;
  padding-bottom: 20px;
  text-align: center;

  h1 {
    margin-bottom: 10px;
  }

  img {
    margin: 30px 0;
  }

  .resend {
    margin-top: 50px;

    .button {
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
