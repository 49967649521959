@import "Variables";
@import "Fonts";

// greenish-yellow gradient button
@mixin button {
  height: 40% !important; //!important is required to override the predefined CSS in the election reminders form
  width: 100% !important;
  font-family: $bebas-neue !important;
  font-size: 200% !important;
  text-align: center !important;
  background: linear-gradient(270deg, #ffed10 0%, #02ddc3 100%) !important;
  color: black !important;
  border: 4px solid black !important;
  border-radius: 40px !important;
  position: relative;
  padding: 5px;

  ::after {
    content: "";
    position: absolute;
    border-radius: 40px;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    border: white 4px solid;
  }
}

@mixin secondary-button {
  height: 40%;
  width: 100%;
  font-family: $bebas-neue;
  font-size: 200%;
  text-align: center;
  background: black;
  background: linear-gradient(270deg, #ffed10 0%, #02ddc3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: 4px solid white;
  border-radius: 40px;
  position: relative;
  padding: 5px;

  ::after {
    z-index: -1;
    content: "";
    position: absolute;
    border-radius: 40px;
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    border: white 4px solid;
  }
}

//black button with greenish-yellow gradient applied to the text. The button should have a <span> element as a child
@mixin inverted-button {
  height: 40%;
  width: fit-content;
  font-family: $bebas-neue;
  text-align: center;
  background-color: #101010;
  border-radius: 40px;
  border: 4px solid $white;
  position: relative;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 20px;
  padding-right: 20px;

  :first-child {
    //a span element should be added inside the button to contain the text, these styles will apply to it
    background: linear-gradient(270deg, #ffed10 0%, #02ddc3 100%);
    // set the background clip to clip around the text content
    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    //set the text fill color to transparent
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
    color: transparent;
    font-size: 32px;
  }
}

//headings
@mixin heading-text($color: $black, $fontSize: 48px) {
  color: $color !important;
  font-family: $bebas-neue !important;
  font-size: $fontSize !important;
}

@mixin subheading-text($color: $black, $fontSize: 36px) {
  color: $color;
  font-family: $bebas-neue;
  font-size: $fontSize;
}

@mixin text($color: $black, $fontSize: 18px) {
  color: $color;
  font-family: $lato;
  font-size: $fontSize;
}

//blue text underline
@mixin text-underline {
  text-decoration: underline;
  text-decoration-color: $blue;
  text-decoration-thickness: 0.5rem;
  text-decoration-skip-ink: none;

  // non-iOS devices (code breaks on iOS)
  @supports not (-webkit-touch-callout: none) {
    text-decoration: underline $blue 0.5rem !important;
    text-underline-offset: -0.25rem !important;
  }
}
