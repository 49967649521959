#navbar {
  background-color: black;
  height: 57px;
  overflow: hidden;
  position: fixed;
  top: 0;
  width: 100%;
  justify-content: center;
}

#navbar-share {
  background-color: black;
  height: 57px;
  padding: 0px 10px 0px 10px;
  overflow: hidden;
  width: 100%;
  justify-content: center;
}

.navbar-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 500px;
  width: 100%;
}

#brand-logo {
  height: 38px;
  padding-left: 15px;
}

#icons-tray {
  height: 35px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#feedback-icon {
  img {
    height: 30px;
    width: 30px;
  }
  position: relative;
  bottom: 2px;
  margin-right: 15px;
  cursor: pointer;
  display: inline;
  background: transparent;
  border: 0px;
}

#sidebar-icon {
  font-size: 30px;
  position: relative;
  bottom: 2px;
}

a.nav-link {
  padding: 0;
  color: white;
}

a.nav-link:hover {
  color: white;
}

a.navbar-brand {
  padding: 0;
}

/* Sidebar */
.nav-container {
  position: fixed;
  top: 0;
  max-width: 500px;
  min-width: 375px;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  opacity: 0;
  z-index: -1;
  right: -380px;
  transition: 400ms;
}

.nav-container.active {
  right: auto;
  transition: 400ms;
  opacity: 1;
  z-index: 100;
  width: 100vw;
}

.nav-menu {
  background-color: black;
  color: white;
  max-width: 80vw;
  min-width: 325px;
  width: 380px;
  display: flex;
  justify-content: center;
}

.menu-items {
  width: 100%;
  overflow: auto;
  padding: 20px 30px;
}
