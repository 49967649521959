@import "../../../styles/Components";
@import "../../../styles/Fonts";
@import "../../../styles/Variables";

.why8by8 {
  div {
    position: relative;
  }
  min-width: 375px;
}
img {
  -moz-drag-over: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  max-width: 500px;
}
q {
  font-family: $lato;
  font-weight: 400;
  font-size: 18px;
}
.whyHero {
  background-color: #000000;
  //   height: 450px;
  color: white;
  .padding {
    padding: 0 30px;
  }
  h1 {
    padding: 0.7em 0em;
  }
  p {
    font-family: $lato;
    font-weight: 300;
    font-size: 18px;
  }
  .custom-shape-divider-top-1659139317 {
    bottom: -35px;
    background: $blue;
  }
}
aside {
  position: relative;
  display: block;
  height: 80px;
  span {
    font-family: $lato;
    font-weight: 500;
    font-size: 12px;
    padding-top: 1em;
    position: absolute;
    top: 1em;
    display: block;
  }
  img {
    display: inline;
    border-radius: 50%;
    position: absolute;
    right: -1em;
    top: 2em;
    z-index: 2;
  }
}
.tealStats {
  background-color: $blue;
  position: relative;
  padding: 100px 30px 50px 30px;
  overflow: hidden;
  h1 {
    font-size: 64px;
    font-family: $bebas-neue;
    color: $yellow;
    display: inline;
    padding-left: 10px;
  }
  h3 {
    padding-left: 10px;
    font-size: 36px;
    color: $black;
    display: inline;
    font-family: $bebas-neue;
    line-height: 58px;
  }
}
u {
  @include text-underline;
}
.whiteMidsection {
  height: fit-content;
  background-color: white;
  padding: 180px 30px 80px 30px;
  h1 {
    font-family: $bebas-neue;
    font-size: 36px;
    color: black;
    text-align: center;
    transform: translateY(-4.5em);
  }
  p {
    font-family: $lato;
    font-weight: 300;
    font-size: 18px;
  }
  .tealCurve {
    transform: rotateX(180deg);
    position: absolute;
    top: -3%;
    left: 0;
    width: 100%;
  }
  .blur-divider {
    min-width: 375px;
    width: 100vw;
    transform: translateX(-2em);
  }
  .chart {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-150px);
  }
  h3 {
    font-family: $lato;
    font-weight: 400;
    font-size: 21px;
    line-height: 24px;
    letter-spacing: 0.4px;
    position: relative;
    transform: translateY(-100px);
    img {
      position: absolute;
      right: -2em;
      top: -200%;
    }
  }
  .lastStat {
    position: relative;
    background: url(../../../assets/images/Why8by8/Blob.png) 50% -130% no-repeat;
    h3 {
      font-family: $bebas-neue;
      font-size: 24px;
      letter-spacing: 3%;
      color: black;
      text-align: center;
      padding-top: 2em;
      line-height: 30px;
      span {
        color: white;
        font-size: 64px;
        line-height: 110px;
        text-align: center;
      }
    }
    .blurBlob {
      transform: rotateY(180deg);
      position: absolute;
      top: -80%;
      left: -2em;
    }
  }
}

.finalTealStats {
  background-color: $blue;
  padding: 0 2em;
  font-family: $bebas-neue;
  text-align: left;
  h2 {
    font-size: 36px;
    letter-spacing: 2px;
  }
  h1 {
    font-size: 64px;
    color: $yellow;
  }
  img {
    position: absolute;
    left: 0;
    transform: translateY(-99%);
    width: 100vw;
    max-width: 500px !important;
    z-index: 10 !important;
  }
  .stat {
    h3 {
      font-family: $bebas-neue;
      font-size: 24px;
      letter-spacing: 3%;
      color: black;
      text-align: center;
      padding-top: 2em;
      line-height: 30px;
      span {
        color: white;
        font-size: 64px;
        line-height: 100px;
        text-align: center;
      }
    }
    &:nth-of-type(1) {
      background: url(../../../assets/images/Why8by8/blob1.svg) 50% 55px
        no-repeat;
    }
    &:nth-of-type(2) {
      background: url(../../../assets/images/Why8by8/blob2.svg) 50% 60px
        no-repeat;
    }
  }
}
.closingQuotes {
  padding: 180px 30px 0 30px;
  .img1 {
    margin-top: -40px;
  }
  .quote-gap {
    margin-top: 125px;
  }
  .chefusQuote {
    padding: 150px 2em;
    transform: translateX(-1.9em);
    background-color: $blue;
    width: 100vw;
    max-width: 500px;
    min-width: 375px;
    height: 350px;
    .logo {
      z-index: 10 !important;
      height: 160px;
      width: 160px;
      border-radius: 50%;
      background: url(../../../assets/images/Why8by8/chefus.png) 50% 50%
          no-repeat,
        #ffffff;
    }
  }
  .tealBottom {
    top: -15px;
  }
}
.pseudoFooter {
  background-color: #000000;
  min-height: 500px;
  z-index: -1;
  padding: 200px 2em 0 2em;
  border-bottom: #000000 10px solid;
  text-align: center;
  h4 {
    font-family: $lato;
    font-size: 18px;
    color: #ffffff;
  }
  h3 {
    font-family: $bebas-neue;
    font-size: 24px;
    letter-spacing: 1px;
    color: white;
    text-align: center;
    padding-top: 2em;
    line-height: 30px;
    span {
      padding: 15px 0;
      color: white;
      font-size: 64px;
      line-height: 74px;
      text-align: center;
      display: block;
      background: url(../../../assets/images/Why8by8/blob1.svg) center no-repeat;
    }
  }
  p {
    font-family: $lato;
    font-size: 12px;
  }
}
.linksBox {
  background-color: #000000;
  padding: 0 2em 2em 2em;
  text-align: center;
  a {
    font-family: $lato;
    font-weight: 400;
    color: $blue;
    font-size: 18px;
    text-decoration: underline;
    text-underline-offset: 3px;
  }
  span {
    font-size: 12px;
    color: #ffffff;
    a {
      font-size: 12px;
      color: #ffffff;
    }
  }
}
.top-curve {
  width: 100%;
  min-width: 375px;
}
.leftSpread {
  position: absolute;
  left: 0;
  width: 100%;
  min-width: 375px;
}
.bold-shadow {
  -webkit-text-stroke: 2px #101010;
  text-shadow: -4px 5.5px 0 #101010;
}
.custom-shape-divider-top-1659139317 {
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
  z-index: 1;
}

.custom-shape-divider-top-1659139317 svg {
  position: relative;
  display: block;
  max-width: 500px;
  height: 150px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1659139317 .shape-fill {
  fill: #000000;
}
