@import "src/styles/Components";
@import "src/styles/Fonts";
@import "src/styles/Variables";

.homepage {
  @mixin bold-shadow($color: $white) {
    color: $color;
    -webkit-text-stroke: 2px $black;
    text-shadow: -4px 2.5px 0px $black;
  }

  @mixin blob-background(
    $background: url(../../../assets/images/HomePage/Blob1.svg)
  ) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 150px;
    width: 280px;
    background-repeat: no-repeat;
    background-position: center;
    text-align: center;
    font-family: $bebas-neue;
    font-size: 64px;
    line-height: 77px;
    letter-spacing: 0.03em;
    @include bold-shadow();
    background-image: $background;
  }

  .curve-div {
    height: 0px;
    .curve {
      width: 100%;
      position: relative;
      bottom: 140px;
    }
    .curve-1 {
      bottom: 160px;
    }
  }

  .content-1 {
    background-image: url(../../../assets/images/HomePage/Background.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 100px;
    .description {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 0 40px;
      .logo {
        margin: 50px 0;
        width: 150px;
      }
      .text {
        @include heading-text($white);
        width: 100%;
        line-height: 58px;
        .underline {
          @include text-underline;
        }
      }
      .challenge-button {
        margin: 50px 0;
        @include button;
      }
    }
  }

  .content-2 {
    background-color: $yellow;
    position: relative;
    .speech-bubble {
      margin: 30px 0px 10px 30px;
    }
    .sign {
      width: 320px;
      max-width: 100vw;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  .content-3 {
    background-color: $white;
    padding: 30px 30px 110px 30px;
    p {
      font-family: $lato;
      font-size: 21px;
      line-height: 24px;
      letter-spacing: 0.02em;
      margin: 0;
    }
  }

  .content-4 {
    background-color: $blue;
    position: relative;
    padding: 30px 30px 70px 30px;
    p {
      font-family: $bebas-neue;
      font-size: 36px;
      line-height: 43px;
      letter-spacing: 0.03em;
      margin: 0;
    }
    .bold-shadow {
      font-size: 64px;
      line-height: 77px;
      @include bold-shadow($yellow);
    }
  }

  .content-5 {
    background-color: $yellow;
    display: flex;
    flex-direction: column;
    .bold-shadow {
      font-family: $bebas-neue;
      font-size: 48px;
      line-height: 58px;
      text-align: right;
      height: 80px;
      margin-right: 30px;
      @include bold-shadow($white);
    }
    .speech-bubble {
      margin: 30px 0 0 30px;
      width: 225px;
    }
    .mic {
      width: 225px;
      max-width: calc(100vw / 2);
      min-width: 140px;
      margin-left: 20px;
    }
  }

  .content-6 {
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 100px;
    .text {
      @include heading-text($black, 36px);
      margin: 50px 30px 15px 30px;
      line-height: 43.2px;
      letter-spacing: 0.03em;
      .underline {
        @include text-underline;
      }
    }
    .stat-1 {
      @include blob-background();
    }
    .stat-2 {
      @include blob-background(url(../../../assets/images/HomePage/Blob2.svg));
    }
    .stat-3 {
      @include blob-background(url(../../../assets/images/HomePage/Blob3.svg));
    }
    .stat {
      max-width: 320px;
      position: relative;
      margin: 0;
      text-align: center;
      top: 32px;
      font-family: $bebas-neue;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.03em;
      padding: 0 20px;
    }
    .bottom-stat {
      max-width: 320px;
      position: relative;
      margin: 0;
      text-align: center;
      bottom: 32px;
      font-family: $bebas-neue;
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.03em;
      padding: 0 20px;
    }
  }

  .content-7 {
    background-color: $black;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    .speech-bubble {
      max-width: 300px;
      max-width: 100%;
    }
    .text {
      @include heading-text($white, 36px);
      line-height: 43px;
      letter-spacing: 0.03em;
      margin: 40px 0;
      .underline {
        @include text-underline;
      }
    }
    .challenge-button {
      @include button;
    }
    .description {
      color: $white;
      font-family: $lato;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: 0.02em;
      margin-top: 45px;
      .link {
        color: $blue;
        text-decoration: underline;
        text-underline-offset: 2px;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
