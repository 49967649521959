@import "../../../styles/Components.scss";
@import "../../../styles/Fonts.scss";
@import "../../../styles/Variables.scss";

.error-modal-outer-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1000;
}
.error-modal-inner-container {
  background-color: rgba(111, 111, 111, 0.5);
  width: 100%;
  max-width: 500px;
  min-width: 375px;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadeIn 250ms ease-in-out 1 forwards;
}

.error-modal {
  background-color: white;
  padding: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: $lato;
  box-sizing: border-box;
  width: 315px;
}

.error-modal-toggle-btn-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.error-modal-toggle-btn {
  height: 24px;
  background-color: transparent;
  border: none;
  outline: none;
}

.error-modal-toggle-btn:hover {
  cursor: pointer;
}

.error-modal-heading {
  font-weight: bold;
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 12px;
  text-align: center;
  font-family: $lato;
  font-size: 18px;
}

.error-modal-text {
  margin-left: 4px;
  margin-right: 4px;
  font-size: 16px;
  text-align: center;
}

.error-modal-ok-btn {
  @include button;
  margin-top: 24px;
  margin-bottom: 24px;
  padding-left: 56px;
  padding-right: 56px;
  width: fit-content !important;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}