@import "src/styles/main";

.progress-page {
  .section-1 {
    .days-blob-container {
      height: 0px;
      bottom: 110px;
      float: right;
      position: relative;
      .days-label {
        @include horizontal-center;
        flex-direction: column;
        position: absolute;
        left: 40%;
        top: -8px;
        p {
          font-size: 122px;
          font-family: $bebas-neue;
          line-height: 110px;
          margin: 0;
        }
      }
      .blob {
        width: 180px;
        height: 220px;
        position: relative;
        bottom: 50px;
        right: 1px;
      }
    }
    @include xs-screen {
      .days-blob-container {
        .days-label {
          left: 40%;
          top: -8px;
          p {
            font-size: 132px;
            line-height: 120px;
          }
        }
        .blob {
          width: 200px;
          height: 220px;
        }
      }
    }
  }

  .section-2 {
    span {
      font-size: 48px;
    }
  }

  .section-3 {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto auto;
    grid-template-columns: auto auto;
    overflow: hidden;
    .badges {
      @include center;
      margin-bottom: 32px;
      .blob {
        @include center;
        position: relative;
        z-index: 0;
        width: 0px;
        img {
          width: 182px;
          height: 183px;
        }
      }
      .disable {
        opacity: 0.2;
      }
      .blob-content {
        @include center;
        flex-direction: column;
        position: relative;
        z-index: 0.1;
        width: 0;
        .icon {
          width: 100px;
          height: 100px;
          margin-bottom: 8px;
        }
        .index {
          color: $white;
          font-size: 72px;
          font-family: $bebas-neue;
          line-height: 120px;
          margin: 0;
        }
        h3 {
          width: 120px;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .badges {
      .blob-img {
        position: relative;
      }

      .blob-img-1 {
        right: 16px;
      }

      .blob-img-3 {
        right: 16px;
      }

      .blob-img-7 {
        bottom: 10px;
        right: 10px;
      }

      .blob-img-8 {
        left: 20px;
      }
    }
  }
}

.couponContainer {
  max-width: 80%;
  background-color: $cream;
  position: relative;
  margin: 24px auto 40px;
  border-radius: 12px;
  transform: translate(-15px, 0%);
  padding: 20px;
  top: 100px;
  * {
    margin: 0.5em auto !important;
  }
  p {
    color: black;
    text-align: center;
    &:nth-of-type(2) {
      color: $graphite;
      font-size: 9px;
    }
  }
  img {
    max-height: 94px;
    width: auto;
    max-width: 60%;
    margin-left: 50% !important;
    transform: translateX(-50%);
  }
  .img-bg {
    background-color: white;
    padding: 20px;
    min-height: 94px;
    width: fit-content;
  }
}
