@import "./Components.scss";
@import "./Fonts.scss";
@import "./Variables.scss";

// title with underline
.normal-title {
  @include text-underline;
  font-family: $bebas-neue;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.03em;
  text-align: center;
}

// title without underline
.no-underline-title {
  font-family: $bebas-neue;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.03em;
  text-align: center;
}

// small title with underline
.small-title {
  @include text-underline;
  font-family: $bebas-neue;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 43px;
  letter-spacing: 0.03em;
  text-align: center;
}

// heading without underline
.normal-heading {
  font-family: $bebas-neue;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.03em;
}

// normal text font
.normal-text {
  font-family: $lato;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.02em;
}

// smaller text font
.small-text {
  font-family: $lato;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
}

// button style
.gradient-button {
  @include button;
}

// center image
.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
